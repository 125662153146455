export default function getCountryList() {
    let list = [];
    list.push("Afghanistan");
    list.push("Albania");
    list.push("Algeria");
    list.push("Andorra");
    list.push("Angola");
    list.push("Antigua and Barbuda");
    list.push("Argentina");
    list.push("Armenia");
    list.push("Australia");
    list.push("Austria");
    list.push("Azerbaijan");
    list.push("Bahamas");
    list.push("Bahrain");
    list.push("Bangladesh");
    list.push("Barbados");
    list.push("Belarus");
    list.push("Belgium");
    list.push("Belize");
    list.push("Benin");
    list.push("Bhutan");
    list.push("Bolivia");
    list.push("Bosnia and Herzegovina");
    list.push("Botswana");
    list.push("Brazil");
    list.push("Brunei");
    list.push("Bulgaria");
    list.push("Burkina Faso");
    list.push("Burundi");
    list.push("Cabo Verde");
    list.push("Cambodia");
    list.push("Cameroon");
    list.push("Canada");
    list.push("Central African Republic (CAR)");
    list.push("Chad");
    list.push("Chile");
    list.push("China");
    list.push("Colombia");
    list.push("Comoros");
    list.push("Congo, Democratic Republic of the");
    list.push("Congo, Republic of the");
    list.push("Costa Rica");
    list.push("Cote d'Ivoire");
    list.push("Croatia");
    list.push("Cuba");
    list.push("Cyprus");
    list.push("Czechia");
    list.push("Denmark");
    list.push("Djibouti");
    list.push("Dominica");
    list.push("Dominican Republic");
    list.push("Ecuador");
    list.push("Egypt");
    list.push("El Salvador");
    list.push("Equatorial Guinea");
    list.push("Eritrea");
    list.push("Estonia");
    list.push("Eswatini (formerly Swaziland)");
    list.push("Ethiopia");
    list.push("Fiji");
    list.push("Finland");
    list.push("France");
    list.push("Gabon");
    list.push("Gambia");
    list.push("Georgia");
    list.push("Germany");
    list.push("Ghana");
    list.push("Greece");
    list.push("Grenada");
    list.push("Guatemala");
    list.push("Guinea");
    list.push("Guinea-Bissau");
    list.push("Guyana");
    list.push("Haiti");
    list.push("Honduras");
    list.push("Hungary");
    list.push("Iceland");
    list.push("India");
    list.push("Indonesia");
    list.push("Iran");
    list.push("Iraq");
    list.push("Ireland");
    list.push("Israel");
    list.push("Italy");
    list.push("Jamaica");
    list.push("Japan");
    list.push("Jordan");
    list.push("Kazakhstan");
    list.push("Kenya");
    list.push("Kiribati");
    list.push("Kosovo");
    list.push("Kuwait");
    list.push("Kyrgyzstan");
    list.push("Laos");
    list.push("Latvia");
    list.push("Lebanon");
    list.push("Lesotho");
    list.push("Liberia");
    list.push("Libya");
    list.push("Liechtenstein");
    list.push("Lithuania");
    list.push("Luxembourg");
    list.push("Madagascar");
    list.push("Malawi");
    list.push("Malaysia");
    list.push("Maldives");
    list.push("Mali");
    list.push("Malta");
    list.push("Marshall Islands");
    list.push("Mauritania");
    list.push("Mauritius");
    list.push("Mexico");
    list.push("Micronesia");
    list.push("Moldova");
    list.push("Monaco");
    list.push("Mongolia");
    list.push("Montenegro");
    list.push("Morocco");
    list.push("Mozambique");
    list.push("Myanmar (formerly Burma)");
    list.push("Namibia");
    list.push("Nauru");
    list.push("Nepal");
    list.push("Netherlands");
    list.push("New Zealand");
    list.push("Nicaragua");
    list.push("Niger");
    list.push("Nigeria");
    list.push("North Korea");
    list.push("North Macedonia (formerly Macedonia)");
    list.push("Norway");
    list.push("Oman");
    list.push("Pakistan");
    list.push("Palau");
    list.push("Palestine");
    list.push("Panama");
    list.push("Papua New Guinea");
    list.push("Paraguay");
    list.push("Peru");
    list.push("Philippines");
    list.push("Poland");
    list.push("Portugal");
    list.push("Qatar");
    list.push("Romania");
    list.push("Russia");
    list.push("Rwanda");
    list.push("Saint Kitts and Nevis");
    list.push("Saint Lucia");
    list.push("Saint Vincent and the Grenadines");
    list.push("Samoa");
    list.push("San Marino");
    list.push("Sao Tome and Principe");
    list.push("Saudi Arabia");
    list.push("Senegal");
    list.push("Serbia");
    list.push("Seychelles");
    list.push("Sierra Leone");
    list.push("Singapore");
    list.push("Slovakia");
    list.push("Slovenia");
    list.push("Solomon Islands");
    list.push("Somalia");
    list.push("South Africa");
    list.push("South Korea");
    list.push("South Sudan");
    list.push("Spain");
    list.push("Sri Lanka");
    list.push("Sudan");
    list.push("Suriname");
    list.push("Sweden");
    list.push("Switzerland");
    list.push("Syria");
    list.push("Taiwan");
    list.push("Tajikistan");
    list.push("Tanzania");
    list.push("Thailand");
    list.push("Timor-Leste");
    list.push("Togo");
    list.push("Tonga");
    list.push("Trinidad and Tobago");
    list.push("Tunisia");
    list.push("Turkey");
    list.push("Turkmenistan");
    list.push("Tuvalu");
    list.push("Uganda");
    list.push("Ukraine");
    list.push("United Arab Emirates (UAE)");
    list.push("United Kingdom (UK)");
    list.push("United States of America (USA)");
    list.push("Uruguay");
    list.push("Uzbekistan");
    list.push("Vanuatu");
    list.push("Vatican City (Holy See)");
    list.push("Venezuela");
    list.push("Vietnam");
    list.push("Yemen");
    list.push("Zambia");
    list.push("Zimbabwe");
    return list;
}