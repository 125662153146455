import React, { useRef, useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from './menu/Menu';
import MainPage from './mainPage/MainPage';
import Introduction from './introduction/introduction';
import Advantage from './advantage/Advantage';
import IntroVideo from './introVideo/IntroVideo';
import Solution from './solution/Solution';
import Customer from './customer/Customer';
import Register from './register/Register';
import AccountingSoftware from './accountingSoftware/AccountingSoftware';
import About from './about/About';
import Rule from './rule/Rule';
import { withTranslation } from 'react-i18next';
import Partner from './partner/Partner';
import Workshop from './workshop/Workshop';
import ReactLoading from 'react-loading';
import './HomePage.css';

function HomePage({ t }) {
    const arr = [{ name: 'menu.intro' }, { name: 'menu.advantage' }, { name: 'menu.solution' }, { name: 'menu.customer' }]
    const refIntroVideo = useRef(null);
    const refIntro = useRef(null);
    const refAdv = useRef(null);
    const refCust = useRef(null);
    const refRegister = useRef(null);
    const ref = [refIntroVideo,refIntro,refAdv,refCust];

    const location = useLocation();

    const executeScroll = (ref) => {
        window.scrollTo({top: ref.current.offsetTop , behavior: 'smooth'})   
    };
    
    const onClickRegulation = (value) => {

    }

    useEffect(() => {
        
        if(location.pathname == "/cafe24") {
            
            let height = document.body.scrollHeight - 1120;

            if(window.screen.width > 1366) {
                height = document.body.scrollHeight - 1160;
            }

            window.scrollTo(0, height);
        }
    }, []);

    return (
        <div>
            <div id='homePage'>
                <div id='colorBackDiv'>
                    <Menu executeScroll={executeScroll} refer={ref} arr={arr} t={t} />
                    <MainPage />
                </div>
                <div className='siteLayout'>
                    <div>
                        <IntroVideo refer = {refIntroVideo} />
                        <Introduction refer={refIntro} />
                        <Solution />
                        <AccountingSoftware />
                        <Advantage refer={refAdv} />
                    </div>
                    <div id='divCust'>
                            <Customer refer={refCust} />
                            <Partner />
                            <Workshop />
                            <Register onClickRegulation={onClickRegulation} refer={refRegister}/>
                    </div>
                </div>
                <div className='footer'>
                    <About executeScroll={executeScroll} refer={ref} arr={arr} t={t}/>
                </div>
               
            </div>
        </div>
    )
}

export default withTranslation()(HomePage);